import "./Home.css";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import gsap, { selector } from "gsap";

export default function Home() {
  const [volume, setVolume] = useState(false);
  const [play, setPlay] = useState(false);
  const [videoList, setVideoList] = useState([
    {
      link: "https://www.youtube.com/embed/aSeO5UXceuE?controls=0",
      client: "Youreka",
      thumbnail: require("../Images/youreka.webp"),
    },
    {
      link: "https://www.youtube.com/embed/ZbQz3R6T1v8",
      client: "TeamUP Science",
      thumbnail: require("../Images/teamUpScience.webp"),
    },
    {
      link: "https://www.youtube.com/embed/h_uSUh3AZj8",
      client: "ISSS",
      thumbnail: require("../Images/isss.webp"),
    },
    {
      link: "https://www.youtube.com/embed/_SoSd1aWe4M",
      client: "TeamUP Science",
      thumbnail: require("../Images/teamup2.webp"),
    },
    {
      link: "https://www.youtube.com/embed/Qo8mxqwEebo",
      client: "Relay for Life",
      thumbnail: require("../Images/relay4life.webp"),
    },
    {
      link: "https://www.youtube.com/embed/iRPev7aYS9g",
      client: "C3",
      thumbnail: require("../Images/c3thumb.jpg"),
    },
    {
      link: "https://www.youtube.com/embed/UY5sDPXdudY",
      client: "Relay for Life",
      thumbnail: require("../Images/rflthumb.jpg"),
    },
    {
      link: "https://www.youtube.com/embed/7QE8nFra-Fg?si=kJzKbDhwN35-rXRo",
      client: "Youreka Edmonton Introduction 2023/24",
      thumbnail: require("../Images/yrka2.jpg"),
    },

  ]);
  const [index, setIndex] = useState(0);
  const [client, setClient] = useState(videoList[index]["client"]);

  let clientRef = useRef();
  let mDiv = useRef();
  let vidList = useRef();
  let homeCnt = useRef();

  let clientTL = gsap.timeline();

  const toggleVolume = () => {
    if (volume) {
      setVolume(false);
    } else {
      setVolume(true);
    }
  };

  const togglePlay = () => {
    let videoFrame = document.querySelectorAll(".video");
    console.log(videoFrame.contentWindow);
    if (play) {
      videoFrame[index].contentWindow.postMessage(
        '{"event":"command","func":"' + "playVideo" + '","args":""}',
        "*"
      );
      setPlay(false);
    } else {
      videoFrame[index].contentWindow.postMessage(
        '{"event":"command","func":"' + "pauseVideo" + '","args":""}',
        "*"
      );
      setPlay(true);
    }
  };

  const previousVideo = () => {
    if (index !== 0) {
      document.querySelector("#home-container").scroll({
        left: window.innerWidth * (index - 1),
        behavior: "smooth",
      });
      setIndex(index - 1);
    } else {
      document.querySelector("#home-container").scroll({
        left: window.innerWidth * videoList.length - 1,
        behavior: "smooth",
      });
      setIndex(videoList.length - 1);
    }
  };

  const nextVideo = () => {
    if (index !== videoList.length - 1) {
      document.querySelector("#home-container").scroll({
        left: window.innerWidth * (index + 1),
        behavior: "smooth",
      });
      setIndex(index + 1);
    } else {
      document.querySelector("#home-container").scroll({
        left: window.innerWidth * 0,
        behavior: "smooth",
      });
      setIndex(0);
    }
  };

  const jumpVideo = (e) => {
    document.querySelector("#home-container").scroll({
      left: window.innerWidth * Number(e.target.getAttribute("data-index")),
      behavior: "smooth",
    });

    setIndex(Number(e.target.getAttribute("data-index")));
  };

  const getVideos = () => {
    let videoPlaylist = [];

    for (let index = 0; index < videoList.length; index++) {
      videoPlaylist.push(
        <div className="video-container" key={index} muted>
          <div className="video-window">
            <div className="video-player">
              {/* <video className="video" muted>
                    <source
                        src={require("../Images/videos/render3_1.mp4")}
                    />
                </video> */}
              <iframe
                className="video"
                src={
                  videoList[index]["link"] +
                  "?enablejsapi=1&version=3&playerapiid=ytplayer"
                }
                modestbranding="0"
                controls="0"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Embedded youtube"
              />
            </div>
          </div>
        </div>
      );
    }
    return videoPlaylist;
  };

  const getThumbnails = () => {
    let thumbList = [];

    for (let i = 0; i < videoList.length; i++) {
      thumbList.push(
        <img
          className="slideshowThumb"
          src={videoList[i]["thumbnail"]}
          alt="video thumbnail"
          onClick={jumpVideo}
          data-index={i}
        />
      );
    }

    return thumbList;
  };

  useLayoutEffect(() => {
    mDiv.current = document.querySelector(".home-container");
    vidList.current = document.querySelectorAll(".video");
    // vidList.current[0].play();
  }, []);

  useEffect(() => {
    for (let i = 0; i < videoList.length; i++) {
      document
        .querySelector(`[data-index='${i}']`)
        .classList.remove("selected");
    }
    document.querySelector(`[data-index='${index}']`).classList.add("selected");
    clientTL.to(clientRef.current, {
      opacity: "0",
      duration: 0.15,
      ease: "power2.in",
    });
    window.setTimeout(() => {
      setClient(videoList[index]["client"]);
    }, 150);
  }, [index]);

  useEffect(() => {
    clientTL.to(clientRef.current, {
      opacity: "1",
      duration: 0.15,
      ease: "power2.in",
    });
  }, [client]);

  return (
    <div id="home-container" ref={homeCnt}>
      {getVideos()}
      <div id="bottomControls">
        <div className="video-info">
          <div className="info-cohort">
            <span className="info-bold">Client: </span>
            <span ref={clientRef}>{client}</span>
          </div>
          {/* <div className="info-cohort">
          <span className="info-bold">Type: </span>Club Promotion
        </div> */}
        </div>
        <div id="slideSelector">{getThumbnails()}</div>
        <div className="video-controls">
          {/* <button id="volumeBtn" onClick={toggleVolume}>
          {volume ? (
            <img src={require("../Images/volume-mute.png")} alt="mute" />
          ) : (
            <img src={require("../Images/volume.png")} alt="mute" />
          )}
        </button>
        <button id="playBtn" onClick={togglePlay}>
          {play ? (
            <img src={require("../Images/pause.png")} alt="mute" />
          ) : (
            <img
              src={require("../Images/play-button-arrowhead.png")}
              alt="mute"
            />
          )}
        </button> */}
          <button id="previousBtn" onClick={previousVideo}>
            <img src={require("../Images/left-arrow.png")} alt="mute" />
          </button>
          <span id="slideNumber">
            {index + 1}/{videoList.length}
          </span>
          <button id="nextBtn" onClick={nextVideo}>
            <img src={require("../Images/right-arrow.png")} alt="mute" />
          </button>
        </div>
      </div>
    </div>
  );
}
